<template>
    <el-dialog title="Configure Line" width="80%" top="1vh" :visible="true">
        <form method="POST" enctype="multipart/form-data" class="line-form">
            <h6>Shift Definitions</h6>
            <div class="row text-center border-bottom">
                <div class="col" v-if="Line.repeat == 'span'">Start Day</div>
                <div class="col">SU</div>
                <div class="col">MO</div>
                <div class="col">TU</div>
                <div class="col">WE</div>
                <div class="col">TH</div>
                <div class="col">FR</div>
                <div class="col">SA</div>
            </div>
            <div class="row border-bottom mt-2" v-for="(detail, idx) in Line.details" :key="idx">
                <span v-if="isValid(detail) != 40">
                    <el-tooltip content="Hours for this week do not equal 40!" placement="top">
                        <i class="fas fa-exclamation-triangle text-warning fa-2x mt-1 pl-2"></i>
                    </el-tooltip>
                </span>
                <div class="col-sm-2" v-if="Line.repeat == 'span'">
                    <date-picker v-model="detail.start"
                                 mode="date"
                                 :min-date="vDatePickerUTCHack(lineGroup.start_day)"
                                 :max-date="vDatePickerUTCHack(lineGroup.end_day)">
                        <template v-slot="{ inputValue, inputEvents }">
                            <fg-input input-classes="font-weight-bold text-center"
                                      :value="inputValue"
                                      v-on="inputEvents" />
                        </template>
                    </date-picker>
                </div>
                <div class="col">
                    <shift-input name="su" :value="detail"
                                 :shift-length-default="Line.hours" v-focus></shift-input>
                </div>
                <div class="col">
                    <shift-input name="mo" :value="detail"
                                 :shift-length-default="Line.hours"></shift-input>
                </div>
                <div class="col">
                    <shift-input name="tu" :value="detail"
                                 :shift-length-default="Line.hours"></shift-input>
                </div>
                <div class="col">
                    <shift-input name="we" :value="detail"
                                 :shift-length-default="Line.hours"></shift-input>
                </div>
                <div class="col">
                    <shift-input name="th" :value="detail"
                                 :shift-length-default="Line.hours"></shift-input>
                </div>
                <div class="col">
                    <shift-input name="fr" :value="detail"
                                 :shift-length-default="Line.hours"></shift-input>
                </div>
                <div class="col">
                    <shift-input name="sa" :value="detail"
                                 :shift-length-default="Line.hours"></shift-input>
                </div>
                <span>
                    <i class="fas fa-trash fa-2x mt-1 pr-2 text-danger cursor-pointer"
                       @click="Line.details.splice(idx, 1)"></i>
                </span>
            </div>
            <span class="add-line-details" @click="lineDetailAdd"><i
                class="fas fa-plus"></i></span>
            <h6>Line Options</h6>
            <div class="row text-center">
                <div class="col">
                    <label class="mb-0">Select Employee Type</label>
                    <el-select v-model="Line.employee_type"
                               class="w-100"
                               name="employee_type"
                               :class="{'has-danger': errors.has('employee_type')}"
                               data-vv-as="Employee Type"
                               v-validate="'required'">
                        <el-option v-for="type in types" class="select-danger" :value="type.id"
                                   :label="type.type_name" :key="type.id">
                        </el-option>
                    </el-select>
                    <i v-show="errors.has('employee_type')" class="fas fa-exclamation-triangle"></i>
                    <small v-show="errors.has('employee_type')" class="text-danger">
                        {{ errors.first('employee_type') }}
                    </small>
                </div>
                <div class="col">
                    <label class="mb-0">Select Crew</label><br>
                    <el-select v-model="Line.crew_id" class="w-100">
                        <el-option v-for="crew in crews" class="select-danger"
                                   :value="crew.id"
                                   :label="crew.name"
                                   :key="crew.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="col">
                    <label class="mb-0">Select Line Hours</label>
                    <el-select v-model="Line.hours" class="w-100">
                        <el-option v-for="type in lineHours" class="select-danger" :value="type.id"
                                   :label="type.name" :key="type.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="col">
                    <label class="mb-0">Select Repeat Pattern</label>
                    <el-select v-model="Line.repeat" class="w-100">
                        <el-option v-for="type in lineRepeat" class="select-danger" :value="type.id"
                                   :label="type.name" :key="type.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row no-gutters mt-3">
                <div class="col">
                    <fg-input v-model="Line.notes" label="Notes"></fg-input>
                </div>
                <div class="col-3 pl-3">
                    <label>Background Color</label>
                    <el-color-picker v-model="Line.bgcolor" class="d-block"></el-color-picker>
                </div>
            </div>
        </form>
        <div slot="footer">
            <n-button round type="info" @click.native="$emit('close')">Cancel</n-button>
            <n-button round type="success" @click.native="validate" :disabled="$store.getters['loading']">Save Line
            </n-button>
        </div>
    </el-dialog>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { Select, Option, Dialog, ColorPicker } from 'element-ui'
import { ShiftInput } from '@/components/stateless'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const LineRepository = RepositoryFactory.get('line')

export default {
  name: 'line-form',
  props: {
    value: Object,
    lineGroup: Object,
    crews: Array,
    employeeTypes: Array,
    creatingCrewId: Number
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [ColorPicker.name]: ColorPicker,
    ShiftInput,
    DatePicker
  },
  data () {
    return {
      Line: {},
      lineHours: [ // Available line hours
        { id: 8, name: '8 Hour' },
        { id: 10, name: '10 Hour' },
        { id: 99, name: 'Other...' }
      ],
      lineRepeat: [ // Available line repeat options
        { id: 'default', name: 'Default Repeating Pattern' },
        { id: 'span', name: 'Repeat By Dates' }
      ]
    }
  },
  methods: {
    formatDetailDates () {
      // Format dates
      this.Line.details.forEach(l => {
        l.start = moment(l.start).utc().toDate()
      })
    },
    init (type) {
      const t = {
        detail: {
          id: null,
          start: moment(this.lineGroup.start_day).toDate(),
          su: '',
          su_length: null,
          mo: '',
          mo_length: null,
          tu: '',
          tu_length: null,
          we: '',
          we_length: null,
          th: '',
          th_length: null,
          fr: '',
          fr_length: null,
          sa: '',
          sa_length: null
        },
        line: {
          details: [],
          hours: 8,
          repeat: 'default',
          area_id: this.area.id,
          crew_id: this.crewId,
          line_group_id: this.lineGroup.id,
          year: this.facility.bid_year
        }
      }
      return t[type]
    },
    /** Check if line is valid */
    isValid (detail) {
      const sum = 0
      return _.reduce(detail, (result, value, key) => {
        if (_.endsWith(key, '_length')) {
          return result + value
        }
        return result
      }, sum)
    },
    /** Add line detail row */
    lineDetailAdd () {
      // Push the detail onto the line
      this.Line.details.push(this.init('detail'))
    },
    save () {
      if (this.creatingCrewId !== null) {
        LineRepository.create(this.value)
          .then((response) => {
            this.$emit('created', response.data)
            this.$emit('close')
          })
      } else {
        LineRepository.update(this.value.id, this.value)
          .then((response) => {
            this.$emit('saved', response.data)
            this.$emit('close')
          })
      }
    },
    /** Display calendar preview of line */
    validate () {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.save()
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      types: 'facility/employeeTypes',
      area: 'area/area'
    }),
    crewId () {
      return this.creatingCrewId || this.Line.crew_id
    }
  },
  mounted () {
    if (this.creatingCrewId) {
      this.Line = this.init('line')
      this.lineDetailAdd()
    } else {
      this.Line = this.value
      this.formatDetailDates()
    }
  },
  watch: {
    value (n) {
      this.Line = n
    },
    Line (n) {
      this.$emit('input', n)
    }
  }
}
</script>
